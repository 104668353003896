




import { defineComponent, ref } from '@vue/composition-api'
import { RuntimeException } from '@/lib/common/exceptions/RuntimeException'

class ParticipantIndexRuntimeError extends RuntimeException {
  name = 'Participant Index error'
}

export default defineComponent({
  name: 'ParticipantsIndex',
  setup(props, ctx) {
    const id = ref('')

    //created() {
    id.value = ctx.root.$route.params.id ?? ''
    if (!id.value) {
      throw new ParticipantIndexRuntimeError("Can't enter a Participant Editor without a League selected")
    }
    //end created
  },
})
